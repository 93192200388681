import React from 'react';
import './App.css';
import { Unity, useUnityContext } from "react-unity-webgl";
import { Helmet } from "react-helmet";
import AddToHomeScreenButton from "./AddToHomeScreenButton";
import OpenLinkDialog from "./OpenLinkDialog";
import IFrameLinkDialog from "./LinkDialog";
import FullScreenDialog from "./FullScreenDialog";
import { useState, useCallback, useEffect } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import DefaultBackgroundPortrait from './images/background_portrait.jpg';
import DefaultBackgroundLandscape from './images/background_landscape.jpg';
import packageJson from '../package.json';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return "";
}

function int16_to_hex(i) {
  var result = i.toString(16);
  var j = 0;
  while (j + result.length < 4) {
    result = "0" + result;
    j++;
  }
  return result;
}

function bin2hex(str) {
  var result = "";
  for (var i = 0; i < str.length; i++) {
    result += int16_to_hex(str.charCodeAt(i));
  }
  return result;
}

function getUUID(domain) {
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext("2d");
  var txt = domain;
  ctx.textBaseline = "top";
  ctx.font = "14px 'Arial'";
  ctx.fillStyle = "#f60";
  ctx.fillRect(125, 1, 62, 20);
  ctx.fillStyle = "#069";
  ctx.fillText(txt, 2, 15);
  ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
  ctx.fillText(txt, 4, 17);

  var b64 = canvas.toDataURL().replace("data:image/png;base64,", "");
  var bin = atob(b64);
  var crc = bin2hex(bin.slice(-16, -12));
  return crc;
}

function writeArguments() {
  const queryString = window.location.search;

  var search = queryString.substring(1);
  var params = '{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}';
  localStorage.setItem("params", params)

  var host = window.location.host;

  try {
    console.log("get host: " + host);
    localStorage.setItem("host", host);
    var index = host.indexOf(".")
    var name = host.substring(0, index)
    document.title = name.toUpperCase();
  } catch (e) {
    console.log("get host exception");
    localStorage.setItem("host", "");
  }

  localStorage.setItem("platform", isMobile ? "mobile" : "other")
  localStorage.setItem("package_version", packageJson.version)
  localStorage.setItem("fb_dynamic_pixel", packageJson.version)
  localStorage.setItem("fbp", getCookie("_fbp"))
  localStorage.setItem("fbc", getCookie("_fbc"))
  if (isMobile) {
    localStorage.setItem("texture_format", "astc")
  } else {
    localStorage.setItem("texture_format", "dx")
  }

  if (isIOS) {
    localStorage.setItem("operating_system", "ios")
  } else if (isAndroid) {
    localStorage.setItem("operating_system", "android")
  } else {
    localStorage.setItem("operating_system", "pc")
  }
}

function App() {
  const whiteList = ["02d07f0590cc", "01c04c0a1066", "05e0da0e00e1", "02a00a08e04f", "00503003f081", "0480440c0053", "034007083073"]
  var myUUID = ""
  try {
    myUUID = getUUID("MyCanvas")
    localStorage.setItem("web_uuid", myUUID)
  } catch (error) {
    localStorage.setItem("web_uuid", "")
    console.error(error)
  }

  var userLang = ""
  try {
    userLang = navigator.language || navigator.userLanguage;
  } catch (error) {
    console.error(error)
  }

  console.log(packageJson.version); // "1.0.0"
  console.log(userLang)
  console.log(myUUID)
  const { unityProvider, sendMessage, addEventListener, removeEventListener, loadingProgression } = useUnityContext({
    loaderUrl: packageJson.version + "/Build/WebGL.loader.js",
    dataUrl: packageJson.version + "/Build/WebGL.data.unityweb",
    frameworkUrl: packageJson.version + "/Build/WebGL.framework.js.unityweb",
    codeUrl: packageJson.version + "/Build/WebGL.wasm.unityweb",
    streamingAssetsUrl: packageJson.version + "/StreamingAssets",
  });

  const [state, setState] = useState(false);

  const [openUrl, setOpenUrl] = useState(null);
  const [fullscreenState, setfullscreenState] = useState(false);
  const [offsetY, setOffsetY] = useState(0);
  const [isLoaded, setUnityLoaded] = useState(false);
  const [useIFrame, setUseIFrame] = useState(false);

  var mql = window.matchMedia("(orientation: portrait)");
  var isLandscape = !mql.matches
  const [orientation, changeOrientation] = useState(isLandscape ? "landscape" : "portrait")

  const handleA2HS = useCallback(() => {
    console.log("request handle A2HS")
    setState(true)
  }, []);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var host = window.location.host;

  var nameArg = urlParams.get('name')
  var appWebApp = nameArg != null
  var outjump = urlParams.get('outjump') == 1

  const openUrlCallback = useCallback((url) => {
    if (outjump) {
      let n = window.open(url)
      if (n == null) {
        setOpenUrl(url)
      }
    } else if (nameArg == "ninjatapjumprun" || nameArg == "pingpongbattle") {
      // try {
        window.webkit.messageHandlers.openUrl.postMessage({ key: url })
      // } catch (error) {
      //   let n = window.open(url)
      //   if (n == null) {
      //     setOpenUrl(url)
      //   }
      // }
    } else if (appWebApp && nameArg != "cardtotlzcola" && nameArg != "pokerfarm" && nameArg != "slotskatongspin" && nameArg != "dwarfsfortune") {
      var iFrame = url.startsWith("http") || url.startsWith("https") || url.startsWith("www")
      if (url.includes("itunes.apple.com")) {
        iFrame = false
      }

      setUseIFrame(iFrame)
      setOpenUrl(url)
    } else {
      let n = window.open(url)
      if (n == null) {
        setOpenUrl(url)
      }
    }
  }, []);

  const jsCommandCallback = useCallback((command) => {
    console.log("on command " + command)
    if (command == "CloseIFrame") {
      setUseIFrame(false)
      setOpenUrl(null)
    }
  })

  const setUnityLoadFinished = useCallback(() => {
    console.log("set unity load finished")
    setUnityLoaded(true)
  })

  var id = "655023419951738"

  var pixelId = urlParams.get('pixelId')
  if (!pixelId) {
    localStorage.setItem("pixelId", "")
  } else {
    localStorage.setItem("pixelId", pixelId)
    id = pixelId
  }


  var way = urlParams.get('way')

  let s = "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};"
    + "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');"
    + "fbq('init', '" + id + "');fbq('track', 'PageView');"
  let nos = '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=' + id + '&ev=PageView&noscript=1"/>'

  let tt = '!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};'
    + "ttq.load('" + id + "');ttq.page();}(window, document, 'ttq');"

  function Head() {
    return <Helmet>
      <meta name="viewport" content="width=device-width,user-scalable=no,initial-scale=1, minimum-scale=1,maximum-scale=1,minimal-ui=true" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="applcd e-mobile-web-app-status-bar-style" content="black-translucent" />
      <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
      <meta http-equiv="Pragma" content="no-cache" />
      <meta http-equiv="Expires" content="0" />
      <script>
        {way == "tt" ? tt : s}
      </script>
      <noscript>
        {nos}
      </noscript>
    </Helmet>
  }


  var fb_in = urlParams.get('fbIn')
  if (!fb_in) {
    localStorage.setItem("fb_in", "")
  } else {
    localStorage.setItem("fb_in", fb_in)
  }


  var shareCode = urlParams.get('code')
  if (!shareCode) {
    localStorage.setItem("code", "")
  } else {
    localStorage.setItem("code", shareCode)
  }

  var disablefullscreen = urlParams.get('disablefullscreen') == 1

  writeArguments()

  useEffect(() => {
    const enterFullscreen = (e) => {
      var fullscreenElements = document.fullscreenElement
      if (!(fullscreenElements === null)) {
        return
      }

      if (disablefullscreen) {
        return
      }

      console.log("touchend triggered fullscreen");
      if (!isAndroid)
        return

      document.body.requestFullscreen()
    }


    writeArguments()
    var unityCanvasElement = document.getElementsByClassName("unity-canvas")[0]

    var fullscreenDiv = document.getElementById("fullscreen")
    fullscreenDiv.addEventListener('touchend', enterFullscreen);

    document.body.addEventListener("fullscreenchange", () => {
      var fullscreenElements = document.fullscreenElement
      if (fullscreenElements === null) {
        console.log("leave full screen state")
        // unityCanvasElement.style.setProperty("width", window.innerWidth);
        // unityCanvasElement.style.setProperty("height", window.innerHeight);
        setfullscreenState(false)
        fullscreenDiv.addEventListener('touchend', enterFullscreen)
      } else {
        console.log("into full screen state")
        // unityCanvasElement.style.setProperty("width", "100vw");
        // unityCanvasElement.style.setProperty("height", "100vh");
        // setTimeout(()=> {
        //   console.log("scroll 1")
        //   window.scrollTo(0, 1)
        // })
        setfullscreenState(true)
        fullscreenDiv.removeEventListener('touchend', enterFullscreen)

        if (isAndroid) {
          var lockFunction = window.screen.orientation.lock;
          if (lockFunction.call(window.screen.orientation, 'portrait')) {
            console.log('orientation lock to portrait')
          } else {
            console.error('There was a problem in locking the orientation')
          }
        }
      }
    });

    console.log("add touch event")

    window.onresize = () => {
      if (!isIOS) {
        return
      }

      // console.log("scrollY: " + window.scrollY)
      // if (window.scrollY <= 0) {
      //   return
      // }
      console.log("window on resize")
      console.log("width: " + document.documentElement.clientWidth + " height: " + document.documentElement.clientHeight)
      unityCanvasElement.style.setProperty("width", document.documentElement.clientWidth + "px");
      unityCanvasElement.style.setProperty("height", document.documentElement.clientHeight + "px");
    }

    const mediaQueryPortrait = window.matchMedia('(orientation: portrait)');

    if (mediaQueryPortrait.matches) {
      localStorage.setItem("orientation", 'portrait')
      changeOrientation('portrait')
      console.log("===============>init orientation: " + 'portrait')
    } else {
      localStorage.setItem("orientation", 'landscape')
      changeOrientation('landscape')
      console.log("===============>init orientation: " + 'landscape')
    }


    function handleOrientationChange(event) {
      var mql = window.matchMedia("(orientation: portrait)");
      var isLandscape = !mql.matches
      const currentOrientation = isLandscape ? "landscape" : "portrait"
      console.log("currenet orientation: " + currentOrientation)
      changeOrientation(currentOrientation)
    }

    addEventListener("PromptA2HS", handleA2HS);
    addEventListener("OpenUrl", openUrlCallback);
    addEventListener("UnityLoadFinished", setUnityLoadFinished);
    addEventListener("JsCommand", jsCommandCallback);
    mediaQueryPortrait.addEventListener('change', handleOrientationChange);

    return () => {
      removeEventListener("PromptA2HS", handleA2HS);
      removeEventListener("OpenUrl", openUrlCallback);
      removeEventListener("JsCommand", jsCommandCallback);
      removeEventListener("UnityLoadFinished", setUnityLoadFinished);

      mediaQueryPortrait.removeEventListener('change', handleOrientationChange);
    };
  }, [addEventListener, removeEventListener, handleA2HS, sendMessage]);

  function unityContent() {
    if ((userLang == "zh-HK" || userLang == "zh-TW" || userLang == "zh-CN") && !whiteList.includes(myUUID)) {
      return <span></span>
    }

    if (isMobile) {
      var currentRatio = window.devicePixelRatio
      if (orientation == "portrait") {
        const scale = 768 / (window.innerWidth * window.devicePixelRatio)
        currentRatio *= scale
      } else {
        const scale = 1370 / (window.innerWidth * window.devicePixelRatio)
        currentRatio *= scale
      }

      // return <Unity className="unity-canvas" style={{ width: window.innerWidth, height: window.innerHeight }} unityProvider={unityProvider} devicePixelRatio={currentRatio} />
      if (fullscreenState) {
        return <Unity className="unity-canvas" style={{ width: "100vw", height: "100vh" }} unityProvider={unityProvider} devicePixelRatio={currentRatio} />
      } else {
        return <Unity className="unity-canvas" style={{ width: window.innerWidth, height: window.innerHeight }} unityProvider={unityProvider} devicePixelRatio={currentRatio} />
      }
    }

    // const pixelRatio = Math.max(1370 / (window.innerWidth * devicePixelRatio), 768 / (window.innerHeight * devicePixelRatio))
    // console.log("final devicePixelRatio: " + pixelRatio)
    return <Unity className="unity-canvas" style={{ width: "100vw", height: "100vh" }} unityProvider={unityProvider} devicePixelRatio={devicePixelRatio} />
  }

  const BackgroundPortrait = "/loading/" + host + "_portrait.jpg";
  const BackgroundLandscape = "/loading/" + host + "_landscape.jpg";

  return (
    <div>
      {Head()}
      <AddToHomeScreenButton open={state} />
      {!useIFrame && (<OpenLinkDialog openUrl={openUrl} clearUrl={() => setOpenUrl(null)} />)}
      {useIFrame && (<IFrameLinkDialog openUrl={openUrl} clearUrl={() => setOpenUrl(null)} />)}
      <span>
        {!isLoaded && (
          <div style={{
            width: "100vw",
            height: "100vh",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: orientation == 'portrait' ? `url(${BackgroundPortrait})` : `url(${BackgroundLandscape})`
          }}>
            <LinearProgressWithLabel value={loadingProgression * 100} />
          </div>
        )}
      </span>
      <div id="fullscreen">
        {unityContent()}
      </div>
    </div>
  )
}

export default App;
